import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BaseBlocksRenderer from "../components/blocks/base-blocks-renderer"

const LandingTemplate = ({ data }) => {
  const content = data.strapiLanding
  const blocks = content.blocks

  let firstPromoIndex = null
  const promos = {
    promotions: [],
    legalTitle: content.legalTitle,
    __typename: "STRAPI__COMPONENT_PROMOTIONS_PROMOTIONS",
  }
  const sanitizedBlocks = blocks.reduce((acc, block, idx) => {
    if (block.__typename !== "STRAPI__COMPONENT_PROMOTIONS_PROMOTIONS") {
      acc.push(block)
    } else {
      promos.promotions.push(block)
      if (!firstPromoIndex) {
        firstPromoIndex = idx
      }
    }

    return acc
  }, [])

  // set promos in the first given index
  sanitizedBlocks.splice(firstPromoIndex, 0, promos)

  return (
    <Layout as="article">
      <Seo seo={content.defaultSeo} />

      <BaseBlocksRenderer
        blocks={sanitizedBlocks}
        legalTitle={content.legalTitle}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiLanding(slug: { eq: $slug }) {
      id
      slug
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          id
          url
          localFile {
            url
          }
        }
      }
      blocks {
        __typename
        ...BlockQuote
        ...BlockPromotion
        ...BlockHeroSection
        ...BaseSectionTitle
        ...BlockImageSupport
        ...BaseNestedIconCard
        ...BlockResponsiveMedia
        # ...BaseIframeSource
        ...BlockSlider
        ...BaseCTA
        ...BlockVideoEmbed
        ...BlockLegalBase
        ...BlockPromoBox
        ...BlockVoltioBonus
        ...MainSlider
        ...BlockParkingAccordion
        ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
          richTextBody: body {
            data {
              id
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SHARED_PRICE_TEST {
          columns
          priceSectionTitle {
            ...BaseSectionTitle
          }
          pricePick {
            voltio_price {
              ...BasePriceCard
            }
            landing_price {
              ...LandingPriceCard
            }
          }
        }
      }
      legalTitle {
        ...BaseSectionTitle
      }
    }
  }

  fragment LandingPriceCard on STRAPI_LANDING_PRICE {
    id
    type
    order
    title
    price
    priceRange
    subtitle
    rate: perTime
    featured
    clarification {
      data {
        childMarkdownRemark {
          html
        }
      }
    }
    highlightColor
    body {
      data {
        childMarkdownRemark {
          html
        }
      }
    }
    priceCardIcon: landingPriceIcon {
      url
    }
  }
`

export default LandingTemplate
